<i18n>
  {
    "es": {
      "COPIED": "Copiado",
      "COPIED_TO_CLIPBOARD": "Codigo copiado al portapapeles",
    }
  }
</i18n>

<template>
  <div class="flex items-center">
    <span
      class="text-xxs lg:text-xs leading-5 text-center tracking-wider font-medium px-4"
      >{{ blok.title }}</span
    >
    <button
      type="button"
      @click="copyToClipboard"
      class="flex items-center gap-x-2 cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 icon icon-tabler icon-tabler-copy"
        :style="`color: ${svgColor || '#ffffff'}`"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"
        />
        <path
          d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"
        />
      </svg>
      <span class="uppercase underline text-xs tracking-wider lg:text-sm">
        {{ blok.code }}
      </span>
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  svgColor: String,
})

const { addMessage } = useSnackBar()
const { t } = useI18n()

const copyToClipboard = () => {
  navigator.clipboard.writeText(props.blok.code)
  addMessage({
    type: 'success',
    result: t('COPIED'),
    text: t('COPIED_TO_CLIPBOARD'),
  })
}
</script>
